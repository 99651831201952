<template>
  <div id="appL">
    <Header />
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="info"
      :sticky="true"
      id="navbar"
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <!-- <b-nav-item to="/">PRINCIPAL</b-nav-item> -->
          <b-nav-item to="/cidade">CIDADE</b-nav-item>
          <b-nav-item href="http://aquidauana.ms.gov.br/edoem/" target="_blank"
            >DIÁRIO OFICIAL</b-nav-item
          >
          <b-nav-item to="/fornecedores">FORNECEDORES </b-nav-item>
          <b-nav-item to="/legislacao">LEGISLAÇÃO</b-nav-item>
          <b-nav-item to="/licitacoes">LICITAÇÕES</b-nav-item>

          <b-nav-item to="/noticias">NOTÍCIAS</b-nav-item>
          <b-nav-item to="/prefeitura">PREFEITURA</b-nav-item>
          <b-nav-item-dropdown text="SECRETARIAS">
            <b-dropdown-item
              @click.prevent="goToPage('/secretaria/3/adminstracao')"
            >
              <p class="menu-title">Administração</p></b-dropdown-item
            >
            <b-dropdown-item @click.prevent="goToPage('/secretaria/12/agecom')">
              <p class="menu-title">Agecom</p></b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="goToPage('/secretaria/11/assistencia-social')"
            >
              <p class="menu-title">Assistência Social</p></b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="goToPage('/secretaria/8/cultura-e-turismo')"
              ><p class="menu-title">Cultura e Turismo</p></b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="goToPage('/secretaria/9/educacao')"
            >
              <p class="menu-title">Educação</p></b-dropdown-item
            >
            <b-dropdown-item @click.prevent="goToPage('/secretaria/14/fema')">
              <p class="menu-title">Fema</p></b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="goToPage('/secretaria/4/financas')"
            >
              <p class="menu-title">Finanças</p></b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="goToPage('/secretaria/6/meio-ambiente')"
              ><p class="menu-title">Meio Ambiente</p></b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="
                goToPage(
                  '/secretaria/5/planejamento-urbanismo-e-obras-publicas'
                )
              "
            >
              <p class="menu-title">
                Planejamento, Urbanismo e Obras Públicas
              </p></b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="goToPage('/secretaria/13/procuradoria-juridica')"
            >
              <p class="menu-title">Procuradoria Jurídica</p></b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="goToPage('/secretaria/7/producao')"
            >
              <p class="menu-title">Produção</p></b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="
                goToPage('/secretaria/10/saude-e-saneamento-basico')
              "
              ><p class="menu-title">Saúde e Saneamento Básico</p>
            </b-dropdown-item>
            <b-dropdown-item
              @click.prevent="
                goToPage('/secretaria/2/servicos-urbanos-e-rurais')
              "
            >
              <p class="menu-title">
                Serviços Urbanos e Rurais
              </p></b-dropdown-item
            >
            <b-dropdown-item
              @click.prevent="goToPage('/secretaria/15/transito')"
            >
              <p class="menu-title">Trânsito</p></b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-nav-item v-if="menu.pregao_ativo == 2" to="/turismo"
            >TURISMO</b-nav-item
          >
          <b-nav-item
            v-if="menu.pregao_ativo == 1"
            :href="menu.pregao_url"
            target="_blank"
          >
            {{ menu.pregao_titulo }}
            <img src="../assets/images/red_circle.gif" width="15px" />
          </b-nav-item>
          <b-nav-item to="/plano-pluri-anual">LDO - PPA - LOA</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
    <CustomFooter />
  </div>
</template>

<script>
import Header from "@/components/Header";
import CustomFooter from "@/components/CustomFooter";
import { onValue } from "firebase/database";
import dataService from "@/services/dataBase";

export default {
  components: {
    Header,
    CustomFooter,
  },
  data: () => ({
    menu: {
      pregao_titulo: "",
      pregao_url: "",
      pregao_ativo: 2,
    },
  }),
  methods: {
    goToPage(page) {
      this.$router.push(page);
    },
  },
  async mounted() {
    const dados = await dataService.getId(
      "pregao",
      "0159c3c0-5846-4959-8d0b-063753fd2697"
    );
    onValue(dados, (snapshot) => {
      const data = snapshot.val();
      this.menu.pregao_titulo = data.pregao_titulo.toUpperCase();
      this.menu.pregao_url = data.pregao_url;
      this.menu.pregao_ativo = data.pregao_ativo;
    });
  },
  beforeDestroy() {
    dataService.off();
  },
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;700;900&display=swap"); */

@font-face {
  font-family: "Poppins";
  src: url("../assets/Fonts/Poppins-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/Fonts/Poppins-Bold.otf") format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/Fonts/Poppins-Black.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "BreveNewsBlack";
  src: url("../assets/Fonts/Breve\ News\ Black.otf") format("opentype");
}
@font-face {
  font-family: "BreveNews";
  src: url("../assets/Fonts/Breve\ News\ Bold.otf") format("opentype");
  font-weight: bold;
}
</style>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";
#appL {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-text-news);
  // max-width: 1440px;
  // margin-right: auto;
  // margin-left: auto;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  box-shadow: 0 0 3px 2px var(--color-shadow);

  #navbar {
    background-color: var(--background-color-secondary) !important;
    display: flex;
    padding: 0 30px;
    min-height: 58.5px;
    margin: auto;

    #nav-collapse {
      @media only screen and (min-width: 1024px) {
        justify-content: center;
      }
    }

    li {
      border-right: 2px solid #2c3e50;
      &:last-child {
        border: none;
      }
    }
    a {
      padding: 0 10px;
      font-weight: bold;
      color: var(--background-color-primary);
      font-size: calc(var(--font-size--large) * 1.2);
      font-size: 18px;
      font-family: "Poppins";
      // border-right: 1px solid #2c3e50;

      .menu-title {
        // font-size: calc(var(--font-size--small) * 1.2);
        font-size: 14.4px;
      }

      &.router-link-exact-active {
        color: var(--color-link-active);
      }

      &.nav-link:hover {
        background-color: var(--background-color-hover);
        border-radius: 5px;
      }
    }
    .dropdown-menu {
      background-color: var(--background-color-secondary) !important;
      width: 280px;
      li {
        border-right: none;
      }
      a {
        padding: 8px;
        .menu-title {
          margin: 0;
          white-space: pre-wrap;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.router-link-exact-active {
          color: var(--color-link-active);
        }
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: var(--background-color-hover);
      }
    }
  }
}
@media only screen and (max-width: 1224px) {
  #appL {
    max-width: 1023px;

    #navbar {
      background-color: var(--background-color-secondary) !important;
      display: flex;
      padding: 0 20px;
      min-height: 48.5px;

      a {
        padding: 0 5px;
        font-size: 16px;
        // font-size: var(--font-size--large);
        // background-color: #334995 !important;
      }
    }
  }
}
@media only screen and (max-width: 990px) {
  #appL {
    #navbar {
      li,
      a {
        padding: 5px;
        border-right: none;
      }
      .dropdown-menu {
        width: auto;
        margin-top: 0;
      }
    }
  }
}
</style>
